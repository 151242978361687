import React from "react";
import { useParams, Link } from "react-router-dom";
import projectsData from "./projectsData";
import RecentProjects from "../components/RecentProjects";

function createMarkup(htmlContent) {
  return { __html: htmlContent || "" };
}

function ProjectPage() {
  const { projectName } = useParams();
  const project = projectsData.find((p) => p.id === projectName);

  if (!project) {
    return <div>Project not found</div>;
  }

  const projectStyle = {
    color: project.fontColor,
    backgroundColor: project.backgroundColor,
  };

  const hrStyle = {
    backgroundColor: project.fontColor,
    height: "1px",
  };
  const buttonStyle = {
    backgroundColor: project.fontColor,
    color: project.backgroundColor,
  };

  return (
    <div style={projectStyle}>
      <div className="pt-48 px-[6vw] xl:px-[12vw] 2xl:px-[16vw]">
        <div className="">
          <h1 className="mb-8 md:mb-12 text-xl md:text-2xl xl:text-3xl">
            {project.client}
          </h1>
          <h2 className="text-[9.5vw] lg:text-[7vw] tracking-tight leading-tight md:leading-none -ml-[0.03em]">
            {project.title}
          </h2>
        </div>
        <div className="flex mt-12 md:mt-24 flex-col lg:flex-row">
          <div className="w-full lg:w-1/3 space-y-4 mb-36 lg:mb-0">
            <div className="w-full lg:pr-[8vw] mt-2">
              <p className="opacity-50 mb-3">Scope</p>
              <p className="mb-10 uppercase text-sm leading-tight">
                {project.scope1 && (
                  <span>
                    {project.scope1}
                    <br />
                  </span>
                )}
                {project.scope2 && (
                  <span>
                    {project.scope2}
                    <br />
                  </span>
                )}
                {project.scope3 && (
                  <span>
                    {project.scope3}
                    <br />
                  </span>
                )}
               {project.scope4 && (
                  <span>
                    {project.scope4}
                    <br />
                  </span>
                )}
              </p>
              <p>
                {project.role && (
                        <p>
                          <p className="opacity-50 mb-3">Role</p>
                          <p className="mb-10 leading-tight">
                            <span>{project.role}</span>
                          </p>
                        </p>
                      )}
                </p>
                <p>
                        <p>
                          <p className="opacity-50 mb-3">Tools</p>
                          <p className="mb-10 leading-tight">
                          {project.tools1 && (
                            <span>
                              {project.tools1}
                              <br />
                            </span>
                          )}
                          {project.tools2 && (
                            <span>
                              {project.tools2}
                              <br />
                            </span>
                          )}
                          {project.tools3 && (
                            <span>
                              {project.tools3}
                              <br />
                            </span>
                          )}
                           {project.tools4 && (
                            <span>
                              {project.tools4}
                              <br />
                            </span>
                          )}
                          </p>
                        </p>
                </p>
              <a
                className="inline-flex h-10 pt-[0.1em] px-8 items-center rounded-full"
                style={buttonStyle}
                href={project.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="mx-auto uppercase text-sm">
                  {project.status}
                </span>
              </a>
            </div>
          </div>
          <div className="w-full lg:w-2/3">
            <p className="text-xl md:text-3xl p-mb-10 md:leading-tight">
              <div
                dangerouslySetInnerHTML={createMarkup(project.description)}
              />
            </p>
          </div>
        </div>
        {project.introImageUrl && (
          <img
            src={project.introImageUrl}
            alt={project.title}
            className="mt-24"
          />
        )}
      </div>
      {project.introDescription && (
        <div className="pt-28 px-[6vw] xl:px-[12vw] 2xl:px-[16vw]">
          <div className="max-w-[700px] mx-auto">
            {project.introHeader && (
              <p className="text-3xl md:text-4xl tracking-tight mb-8">
                {project.introHeader}
              </p>
            )}

            <div
              className="p-mb-10 opacity-70 text-lg"
              dangerouslySetInnerHTML={createMarkup(project.introDescription)}
            />
          </div>
        </div>
      )}
      <div className="px-[6vw] xl:px-[12vw] 2xl:px-[16vw] flex-col pt-28 pb-64">
        <div className="flex flex-col space-y-28">
          {Array.from(
            { length: 8 },
            (_, i) =>
              project[`resultSubheader${i + 1}`] && (
                <div key={i}>
                  <div className="max-w-[700px] mx-auto">
                    <p className="text-3xl md:text-4xl tracking-tight mb-8">
                      {project[`resultSubheader${i + 1}`]}
                    </p>
                    {project[`resultDescription${i + 1}`] && (
                      <div
                        className="p-mb-10 opacity-70 text-lg"
                        dangerouslySetInnerHTML={createMarkup(
                          project[`resultDescription${i + 1}`]
                        )}
                      />
                    )}
                  </div>
                  {project[`resultImageUrl${i + 1}`] && (
                    <img
                      src={project[`resultImageUrl${i + 1}`]}
                      alt={project.title}
                      className="mt-16"
                    />
                  )}
                  {project[`resultVideoUrl${i + 1}`] && (
                    <video
                      src={project[`resultVideoUrl${i + 1}`]}
                      alt={project.title} // 'alt' is not valid for video, consider using 'title' for accessibility
                      className="mt-16"
                       // Enables the default control set (play, pause, etc.)
                      autoPlay // Automatically starts playing the video when the component loads
                      muted // Mutes the video to allow autoplay in most browsers
                      loop // Makes the video loop
                    >
                      Sorry, your browser does not support embedded videos.
                    </video>
                  )}
                </div>
              )
          )}
        </div>
      </div>

      <RecentProjects projectsData={projectsData} />
    </div>
  );
}

export default ProjectPage;
